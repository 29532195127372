export default {
  getVersion() {
    return process.env.VUE_APP_VERSION;
  },

  getBasePath() {
    return process.env.VUE_APP_API_URL;
  },

  getNodeEnv() {
    return process.env.NODE_ENV;
  },

  getToken() {
    if (window.localStorage.getItem('agencyflow_login_token')) {
      return JSON.parse(window.localStorage.getItem('agencyflow_login_token'));
    }
  }
};
