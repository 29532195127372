<template>
  <div>
    <div
      class="display-flex justify-content-center align-items-center bg-brand-gray-600 min-vh-100"
      :style="{
        'background-image': `url('${require('@/static/bg-flow.png')}')`
      }"
      v-if="!loginChecked">
      <Toast position="top-right" />

      <div class="flex-fill container-sm">
        <img
          src="@/static/logo.svg"
          alt="Agency Flow"
          width="200"
          height="200"
          class="d-block mx-auto" />
      </div>
    </div>

    <div
      class="display-flex justify-content-center align-items-center bg-brand-gray-600 min-vh-100"
      :style="{
        'background-image': `url('${require('@/static/bg-flow.png')}')`
      }"
      v-if="!userLoggedIn.isLoggedIn && loginChecked">
      <Toast position="top-right" />

      <div class="flex-fill container-sm">
        <img
          src="@/static/logo.svg"
          alt="Agency Flow"
          width="200"
          height="200"
          class="d-block mx-auto" />

        <loginForm />
      </div>
    </div>

    <div v-if="userLoggedIn.isLoggedIn && loginChecked">
      <Toast position="bottom-right" />
      <ConfirmPopup></ConfirmPopup>
      <ScrollTop :threshold="400" />

      <div class="sidebar-container position-fixed w-100 h-100">
        <appSidebar />
      </div>

      <div class="app-container float-right bg-brand-gray-400">
        <Message class="m-0" severity="warn" v-if="envMode != 'production'">
          <strong class="text-uppercase roboto-condensed-400">
            {{ envMode }} environment only
          </strong>
        </Message>

        <appHeader :title="title" :icon="icon" />

        <router-view :key="$route.path" />
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from '@/components/appHeader.vue';
import appSidebar from '@/components/appSidebar.vue';
import loginForm from '@/components/loginForm.vue';
import env from '@/config/env';

import { mapStores } from 'pinia';
import { useLoginStore } from '@/store/login';

export default {
  data() {
    return {
      loginChecked: false, //set to true once the login check is performed.
      title: '',
      icon: '',
      envMode: env.getNodeEnv()
    };
  },
  computed: {
    ...mapStores(useLoginStore),
    userLoggedIn() {
      return this.loginStore.userLoggedIn;
    }
  },
  watch: {
    $route() {
      if (this.$route.meta.title) this.title = this.$route.meta.title;
      if (this.$route.meta.icon) this.icon = this.$route.meta.icon;
    }
  },
  methods: {},
  async mounted() {
    console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL);
    setTimeout(() => {
      this.loginChecked = true;
    }, 1000);
  },

  components: {
    appHeader,
    appSidebar,
    loginForm
  }
};
</script>

<style lang="scss">
@import 'assets/scss/app.styles';

.display-flex.min-vh-100 {
  background-size: cover;

  .container-sm {
    max-width: 600px;
  }
}

.sidebar-container {
  top: 0;
  left: 0;
  max-width: 250px;
}

.app-container {
  width: calc(100% - 250px);
  min-height: 100vh;
}

.p-scrolltop {
  left: calc(50% - 1.5rem);
  margin-left: 125px;
}
</style>
