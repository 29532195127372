import { createApp } from 'vue';
import VuePrime from 'primevue/config';
import Vue3Sanitize from 'vue-3-sanitize';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Toastification from 'vue-toastification';

//import loginService from '@/services/login';

import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
//import Calendar from 'primevue/calendar'; //import as needed.
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';

import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';

import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import TextArea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import VirtualScroller from 'primevue/virtualscroller';
import Menu from 'primevue/menu';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'vue-toastification/dist/index.css';
//import 'primeflex/primeflex.css'; //i want to use it but right now screws up styles.

//import VueScrollingTable from 'vue-scrolling-table';
import './../node_modules/vue-scrolling-table/dist/style.css';

import App from './app.vue';
import router from './router';

//import './registerServiceWorker';

//add jquery to window, used in summernoteEditor
//window.$ = window.jQuery = require('jquery');

const app = createApp(App);

app.use(Vue3Sanitize, {});
app.use(ConfirmationService);
app.use(ToastService);
app.use(Toastification);

import { createPinia } from 'pinia';
const piniaStore = createPinia();
app.use(piniaStore);

app.directive('tooltip', Tooltip);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('ScrollTop', ScrollTop);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('TextArea', TextArea);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('VirtualScroller', VirtualScroller);
app.component('Menu', Menu);
app.component('Card', Card);

//custom components
import projectDropdown from '@/components/form/projectDropdown';
app.component('projectDropdown', projectDropdown);
import companyDropdown from '@/components/form/companyDropdown';
app.component('companyDropdown', companyDropdown);

app.use(VuePrime, { ripple: true });

import format from '@/services/util/format';
app.config.globalProperties.$format = format;

import VueDragscroll from 'vue-dragscroll';
app.use(VueDragscroll);

app.use(router).mount('#app');

console.log('ENV', process.env.NODE_ENV, process.env.VUE_APP_API_URL);
