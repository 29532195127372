<template>
  <div class="app-sidebar shadow bg-brand-gray-600 text-brand-gray-100">
    <ConfirmDialog group="auth"></ConfirmDialog>

    <div
      class="sidebar-header display-flex justify-content-center align-items-center">
      <router-link :to="{ name: 'dashboard' }">
        <img src="@/static/logo.svg" alt="Agency Flow" />
      </router-link>
    </div>

    <div class="sidebar-content overflow-x-hidden overflow-y-auto">
      <PanelMenu :model="allowedItems" :exact="true">
        <template #item="{ item }">
          <router-link
            :to="item.url"
            :class="[
              'display-flex rounded-lg',
              {
                active: checkActiveState(item.url)
              }
            ]"
            v-if="item.url">
            <span class="roboto-400">
              <i
                :class="[
                  'pi position-relative mr-2 text-brand-teal-100',
                  item.icon
                ]"></i>
              {{ item.label }}
            </span>
          </router-link>

          <a
            @click="item.command()"
            :class="['display-flex']"
            v-if="item.command">
            <span class="roboto-400">
              <i
                :class="[
                  'pi position-relative mr-2 text-brand-teal-100',
                  item.icon
                ]"></i>
              {{ item.label }}
            </span>
          </a>

          <a
            @click="
              closeDropdowns();
              item.show = !item.show;
            "
            :class="[
              'display-flex row align-items-center',
              {
                active: checkActiveState(),
                'expanded mt-2': item.show
              }
            ]"
            v-if="item.items && !item.show">
            <div class="col">
              <span class="roboto-400">
                <i
                  :class="[
                    'pi position-relative mr-2 text-brand-teal-100',
                    item.icon
                  ]"></i>
                {{ item.label }}
              </span>
            </div>

            <div class="col-auto">
              <i
                :class="[
                  'pi',
                  item.show ? 'pi-chevron-down' : 'pi-chevron-right'
                ]"></i>
            </div>
          </a>

          <a
            @click="item.show = !item.show"
            :class="[
              'display-flex row align-items-center',
              {
                active: checkActiveState(),
                'expanded mt-2': item.show
              }
            ]"
            v-if="item.items && item.show">
            <div class="col">
              <span class="roboto-400">
                <i
                  :class="[
                    'pi position-relative mr-2 text-brand-teal-100',
                    item.icon
                  ]"></i>
                {{ item.label }}
              </span>
            </div>

            <div class="col-auto">
              <i
                :class="[
                  'pi',
                  item.show ? 'pi-chevron-down' : 'pi-chevron-right'
                ]"></i>
            </div>
          </a>

          <ul class="dropdown my-3 ml-4 mr-0 p-0" v-show="item.show">
            <li
              v-for="(child, childIndex) in item.items"
              :key="childIndex"
              class="display-block">
              <router-link
                :to="child.url"
                :class="[
                  'display-flex position-relative pl-4',
                  {
                    active: checkActiveState(child.url)
                  }
                ]"
                v-if="child.url">
                <span class="roboto-400">
                  <i
                    :class="[
                      'pi position-relative mr-2 text-brand-teal-100',
                      child.icon
                    ]"
                    v-if="child.icon"></i>
                  {{ child.label }}
                </span>
              </router-link>

              <a
                @click="child.command()"
                :class="['display-flex position-relative pl-4']"
                v-if="child.command">
                <span class="roboto-400">
                  <i
                    :class="[
                      'pi position-relative mr-2 text-brand-teal-100',
                      child.icon
                    ]"
                    v-if="child.icon"></i>
                  {{ child.label }}
                </span>
              </a>
            </li>
          </ul>
        </template>
      </PanelMenu>
    </div>

    <div
      class="sidebar-footer display-flex align-items-center px-3 bg-brand-teal-100 text-brand-gray-600">
      <div class="row flex-fill align-items-center">
        <div class="col">
          <router-link
            :to="{ name: 'changelog' }"
            class="roboto-condensed-400 text-brand-gray-600">
            <small>View Change Log</small>
          </router-link>
        </div>

        <div class="col-auto">
          <small class="roboto-condensed-400">{{ version }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import env from '@/config/env';
import PanelMenu from 'primevue/panelmenu';
import loginService from '@/services/login';

export default {
  components: {
    PanelMenu
  },
  data() {
    return {
      current: '',
      allowedItems: [],
      items: [
        {
          label: 'Dashboard',
          icon: 'pi pi-home',
          url: '/dashboard',
          state: 'dashboard',
          permission: 0
        },
        {
          label: 'Timesheet',
          icon: 'pi pi-clock',
          url: '/timesheet',
          state: 'timesheet',
          permission: 0
        },
        {
          label: 'Contacts',
          icon: 'pi pi-id-card',
          url: '/contacts',
          state: 'contacts',
          permission: 'Manager'
        },
        {
          label: 'Projects',
          icon: 'pi pi-briefcase',
          url: '/projects',
          state: 'projects',
          permission: 'Manager'
        },
        {
          label: 'Proposals',
          icon: 'pi pi-send',
          url: '/proposals',
          state: 'proposals',
          permission: 'Manager'
        },
        {
          label: 'Work Orders',
          icon: 'pi pi-paperclip',
          url: '/work-orders',
          state: 'workOrders',
          permission: 'Manager'
        },
        // { label: 'Schedule', icon: 'pi pi-calendar', url: '/schedule', state: 'schedule', permission: 0 },
        {
          label: 'Reports',
          icon: 'pi pi-chart-line',
          items: [
            {
              label: 'Payroll Report',
              url: '/reports/payroll-report',
              state: 'payrollReport',
              permission: 2
            },
            {
              label: 'Personnel Report',
              url: '/reports/personnel-report',
              state: 'personnelReport',
              permission: 2
            },
            {
              label: 'Project Estimates',
              url: '/reports/project-estimates',
              state: 'projectEstimates',
              permission: 2
            },
            {
              label: 'Project Status',
              url: '/reports/project-status',
              state: 'projectStatus',
              permission: 2
            },
            {
              label: 'Single Project Report',
              url: '/reports/single-project',
              state: 'singleProject',
              permission: 2
            },
            {
              label: 'Task Report',
              url: '/reports/task',
              state: 'taskReport',
              permission: 2
            }
          ],
          show: false,
          permission: 2
        },
        {
          label: 'Manage Users',
          icon: 'pi pi-users',
          url: '/settings/users/manage',
          state: 'manageUsers',
          permission: 2
        },
        {
          label: 'Setup & Templates',
          icon: 'pi pi-sliders-h',
          items: [
            {
              label: 'Task Types',
              url: '/setup/task-types',
              state: 'taskTypes',
              permission: 'Manager'
            },
            {
              label: 'Itemized Costs',
              url: '/setup/itemized-costs',
              state: 'itemizedCosts',
              permission: 2
            },
            {
              label: 'Proposal Templates',
              url: '/setup/proposal-templates',
              state: 'proposalTemplates',
              permission: 2
            },
            {
              label: 'Proposal Terms',
              url: '/setup/proposal-terms',
              state: 'proposalTerms',
              permission: 'Manager'
            },
            {
              label: 'Project Types',
              url: '/setup/project-types',
              state: 'projectTypes',
              permission: 2
            },
            {
              label: 'Vendor Items',
              url: '/setup/vendor-items',
              state: 'vendorItems',
              permission: 2
            }
          ],
          show: false,
          permission: 'Manager'
        },
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          items: [
            {
              label: 'Edit Profile',
              icon: 'pi-user-edit',
              url: '/settings/profile/edit',
              state: 'editProfile',
              permission: 0
            },
            {
              label: 'Sign Out',
              icon: 'pi-power-off',
              command: this.logout,
              permission: 0
            }
          ],
          show: false,
          permission: 0
        }
      ],

      version: env.getVersion()
    };
  },
  methods: {
    isAllowed(required) {
      let status = loginService.getAdminStatus();
      return status === 'Admin' || status === required || required == 0;
    },

    closeDropdowns() {
      this.items.forEach(item => {
        if (item.show) {
          item.show = !item.show;
        }
      });
    },

    splitArray(arr, separator) {
      let keywords = [];

      arr = arr.split(separator);
      arr.forEach(item => {
        if (item) {
          keywords.push(item);
        }
      });

      return keywords;
    },

    checkActiveState(url) {
      if (url) {
        url = this.splitArray(url, '/');

        if (this.current.fullPath) {
          return this.current.fullPath.includes(url[url.length - 1]);
        }
      }
    },

    logout() {
      this.$confirm.require({
        group: 'auth',
        header: 'Sign Out',
        message: 'Are you sure you want to sign out?',
        icon: 'pi pi-power-off',
        accept: async () => {
          await loginService.logout();
        },
        reject: () => {}
      });
    }
  },
  watch: {
    $route() {
      this.current = this.$route;
    }
  },
  mounted() {
    this.$nextTick(() => (this.current = this.$route));

    //filter sidebar items based on permission level, go 2 levels deep.
    this.allowedItems = this.items.filter(item => {
      if (item?.items) {
        item.items = item.items.filter(item2 => {
          return this.isAllowed(item2.permission);
        });
      }
      return this.isAllowed(item.permission);
    });
  }
};
</script>

<style lang="scss" scoped>
.app-sidebar {
  height: 100vh;

  .sidebar-header {
    height: 140px;

    img {
      width: auto;
      height: 120px;
    }
  }

  .sidebar-content {
    height: calc(100vh - 190px);
  }

  .sidebar-footer {
    height: 50px;
  }
}
</style>
