import api from './api';

export default {
  async getCompanyOptions() {
    let results = [];
    let data = {};

    let items = await api.search(data, '/endpoints/company/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    return results;
  },

  async getContactOptions(id) {
    let results = [];
    let data;

    if (!id) {
      console.log('getContactOptions', 'no filter tag provided', id);
      return [];
    } else {
      data = {
        filter_tag: id
      };
    }

    let items = await api.search(data, '/endpoints/contact/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    return results;
  },

  async getUserOptions() {
    let results = [];
    let data = {};

    let items = await api.search(data, '/endpoints/user/dropdown/');

    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    return results;
  },

  async getProjectOptions(clients = []) {
    let results = [];
    let data = {
      clients: clients
    };

    let items = await api.search(data, '/endpoints/project/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    return results;
  },

  async getProjectTypeOptions() {
    let results = [];
    let data = {};

    let items = await api.search(data, '/endpoints/project-type/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    results.sort((a, b) =>
      a.TypeName.toLowerCase() > b.TypeName.toLowerCase() ? 1 : -1
    );
    return results;
  },

  async getProposalTemplates() {
    let results = [];
    let data = {
      filter_tag: 'Template'
    };

    let items = await api.search(data, '/endpoints/proposal/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    results.sort((a, b) =>
      a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1
    );
    return results;
  },

  async getProposalTaskListOptions() {
    let results = [];
    let data = {};

    let items = await api.search(
      data,
      '/endpoints/proposal/task-type/dropdown/'
    );
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    //sort
    results.sort((a, b) =>
      a.TaskName.toLowerCase() > b.TaskName.toLowerCase() ? 1 : -1
    );

    return results;
  },

  async getProjectDeliveredProposals(tag) {
    let results = [];
    let data = { DisplayTag: tag };
    let items = await api.search(
      data,
      '/endpoints/project/task-manager/proposal-list/'
    );

    if (items?.rows) {
      results = items.rows;
    }

    results.sort((a, b) =>
      a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1
    );
    return results;
  },

  async getPropoosalItemizedCostListOptions() {
    let results = [];
    let data = {};

    let items = await api.search(
      data,
      '/endpoints/proposal/hard-cost/dropdown/'
    );
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    //sort
    results.sort((a, b) =>
      a.Content.toLowerCase() > b.Content.toLowerCase() ? 1 : -1
    );
    return results;
  },

  async getProposalTermsListOptions(termType = '') {
    let results = [];
    let data = { TermType: termType };

    let items = await api.search(data, '/endpoints/proposal-term/dropdown/');
    Object.entries(items.rows).forEach(item => {
      results.push(item[1]);
    });

    //sort
    results.sort((a, b) =>
      a.TermName.toLowerCase() > b.TermName.toLowerCase() ? 1 : -1
    );

    return results;
  },

  async getTaskListOptions(id) {
    let results = [];
    let data;

    if (id) {
      data = {
        filter_tag: id
      };

      let items = await api.search(
        data,
        '/endpoints/project/task-list/dropdown/'
      );
      Object.entries(items.rows).forEach(item => {
        results.push(item[1]);
      });

      //sort
      results.sort((a, b) =>
        a.Content.toLowerCase() > b.Content.toLowerCase() ? 1 : -1
      );
      return results;
    }
  },

  async getWorkOrderOptions(id) {
    let results = [];
    let data;

    if (id) {
      data = {
        filter_tag: id
      };

      let items = await api.search(
        data,
        '/endpoints/project/work-order/dropdown/'
      );
      Object.entries(items.rows).forEach(item => {
        results.push(item[1]);
      });

      results.sort((a, b) =>
        a.Content.toLowerCase() > b.Content.toLowerCase() ? 1 : -1
      );
      return results;
    }
  }
};
