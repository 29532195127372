<template>
  <div
    :class="['d-inline-block', showDue && overdueLevel > 0 ? 'overdue' : '']"
    v-html="parsedDate"
    v-if="date"></div>
</template>

<script>
export default {
  props: ['date', 'long', 'showDue'],
  data() {
    return {
      value: null,
      parsedDate: '',
      overdueLevel: 0
    };
  },
  methods: {
    parseDate(date) {
      let suffix = '';

      date = new Date(date);

      if (this.showDue) {
        let today = new Date();
        if (dateInPast(date, today)) {
          this.overdueLevel = 1;
        }
      }

      let day = date.getDate();

      if (!this.long) {
        this.parsedDate = `${date.toLocaleDateString('en-US', {
          weekday: 'short'
        })}, ${date.toLocaleDateString('en-US', {
          month: 'short'
        })} ${day}<small style="text-transform: none">${suffix}</small>, ${date.getFullYear()}`;
      } else {
        this.parsedDate = `${date.toLocaleDateString('en-US', {
          weekday: 'long'
        })}, ${date.toLocaleDateString('en-US', {
          month: 'long'
        })} ${day}<small style="text-transform: none">${suffix}</small>, ${date.getFullYear()}`;
      }
    }
  },
  watch: {
    date(value) {
      this.value = value;
      this.parseDate(this.value);
    }
  },
  mounted() {
    this.value = this.date;
    this.parseDate(this.value);
  }
};

var dateInPast = function (firstDate, secondDate) {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};
</script>

<style lang="scss" scoped>
.overdue {
  color: #d9534f;
}
</style>
