<template>
  <div>
    <Dropdown
      v-if="!multiSelect"
      :filter="filter"
      :showClear="showClear"
      :placeholder="placeholder"
      v-model="inputValue"
      :options="options"
      :loading="loading"
      :emptyMessage="emptyMessage"
      optionLabel="Content"
      optionValue="DisplayTag"
      dataKey="DisplayTag"
      class="bg-brand-gray-600">
    </Dropdown>

    <MultiSelect
      v-if="multiSelect"
      :filter="filter"
      :showClear="showClear"
      :placeholder="placeholder"
      v-model="inputValue"
      :options="options"
      :loading="loading"
      :emptyMessage="emptyMessage"
      optionLabel="Content"
      optionValue="DisplayTag"
      dataKey="DisplayTag"
      class="bg-brand-gray-600">
    </MultiSelect>
  </div>
</template>

<script>
import dropdownService from '@/services/dropdowns';
import { defineComponent, computed, ref, onMounted, nextTick } from 'vue';

export default defineComponent({
  name: 'companyDropdown',
  props: {
    modelValue: { type: [String, Array, null], required: true },
    multiSelect: Boolean,
    filter: Boolean,
    showClear: Boolean,
    emptyMessage: { type: String, default: 'No options available' },
    placeholder: {
      type: String,
      default: 'Select Client'
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const dontChange = ref(true); //stops from emitting change when mounted.
    const options = ref([]);
    const loading = ref(false);

    const inputValue = computed({
      get: () => {
        return props.modelValue;
      },
      set: val => {
        emit(
          'update:modelValue',
          props.multiSelect && val ? [val].flat() : val
        );
        if (!dontChange.value) {
          emit('change');
        } else {
          dontChange.value = false;
        }
      }
    });

    const getOptions = async () => {
      options.value = [];
      loading.value = true;
      options.value = await dropdownService.getCompanyOptions();
      await nextTick();
      loading.value = false;
    };

    //get options
    onMounted(async () => {
      await getOptions();
      if (inputValue.value == null) {
        dontChange.value = false;
      }
    });

    return {
      inputValue,
      options,
      loading
    };
  }
});
</script>

<style lang="scss" scoped></style>
