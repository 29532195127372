<template>
  <div class="d-inline-block">
    {{ parsedTime }}
  </div>
</template>

<script>
export default {
  props: ['time', 'interval'],
  data() {
    return {
      parsedTime: ''
    };
  },
  methods: {
    formatTime: function (date) {
      let hour = date.getHours();
      let minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      let suffix = hour < 12 ? 'am' : 'pm';
      hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();

      this.parsedTime = `${hour}:${minutes}${suffix}`;
    }
  },
  mounted() {
    this.formatTime(this.time);
    if (this.interval) {
      setInterval(() => {
        let date = new Date();
        this.formatTime(date);
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
