<!--
custom projects dropdown, can be single or multiselect with multiSelect attribute.
requires a companies attribute to filter results as there are too many projects to call all of them.
-->

<template>
  <div>
    <Dropdown
      v-if="!multiSelect"
      :filter="filter"
      :showClear="showClear"
      :placeholder="placeholder"
      v-model="inputValue"
      :options="options"
      :loading="loading"
      optionLabel="Content"
      optionValue="DisplayTag"
      dataKey="DisplayTag"
      :emptyMessage="emptyMessage"
      class="bg-brand-gray-600">
    </Dropdown>

    <MultiSelect
      v-if="multiSelect"
      :filter="filter"
      :showClear="showClear"
      :placeholder="placeholder"
      v-model="inputValue"
      :options="options"
      :loading="loading"
      optionLabel="Content"
      optionValue="DisplayTag"
      dataKey="DisplayTag"
      :emptyMessage="emptyMessage"
      class="bg-brand-gray-600">
    </MultiSelect>
  </div>
</template>

<script>
import dropdownService from '@/services/dropdowns';
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  nextTick,
  watch
} from 'vue';

export default defineComponent({
  name: 'projectDropdown',
  props: {
    modelValue: { type: [String, Array], default: null, required: false },
    multiSelect: Boolean,
    filter: Boolean,
    showClear: Boolean,
    emptyMessage: { type: String, default: 'Company Selection Required' },
    placeholder: {
      type: String,
      default: 'Select Project'
    },
    companies: { type: [String, Array], default: null, required: false }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const dontChange = ref(true); //stops from emitting change when mounted.
    const options = ref([]);
    const loading = ref(false);

    //handle model value
    const inputValue = computed({
      get: () => {
        return props.modelValue;
      },
      set: val => {
        emit(
          'update:modelValue',
          props.multiSelect && val ? [val].flat() : val
        );
        if (!dontChange.value) {
          emit('change');
        } else {
          dontChange.value = false;
        }
      }
    });

    //api call for options
    const getOptions = async _companies => {
      inputValue.value = null;
      options.value = [];
      if (_companies?.length > 0) {
        loading.value = true;

        options.value = await dropdownService.getProjectOptions(_companies);

        await nextTick();
        loading.value = false;
      }
    };

    //watch if props.companies changes
    watch(
      () => props.companies,
      async newVal => {
        await getOptions(newVal);
      },
      { deep: true, immediate: true }
    );

    //get options
    onMounted(async () => {
      await getOptions(props.companies);
      if (inputValue.value == null) {
        dontChange.value = false;
      }
    });

    return {
      inputValue,
      options,
      loading
    };
  }
});
</script>

<style lang="scss" scoped></style>
