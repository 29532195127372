import axios from 'axios';
import { useToast } from 'vue-toastification';
import env from './../../config/env';
import loginService from '@/services/login';

export default {
  async search(data, url) {
    const toast = useToast();

    let parsedData = new URLSearchParams(Object.entries(data));
    parsedData = parsedData.toString() + `&token=${encodeURI(env.getToken())}`;

    let config = {
      method: 'post',
      url: `${env.getBasePath()}${url}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      data: parsedData
    };

    let response = await axios(config);
    let consoleRes = { ...response };
    console.log('api response', url, data, consoleRes);

    if (response.data.status === 'success') {
      return response.data;
    } else {
      if (response.data.error === 'invalid token') {
        // redirect to login screen
        loginService.logout();
      } else {
        toast.error(response.data.error, { hideProgressBar: true });
      }
    }
  },

  async post(data, url) {
    let checkedData = checkUndefined(data);
    let parsedData = this.serialize(checkedData);
    parsedData = parsedData.toString() + `&token=${encodeURI(env.getToken())}`;

    let config = {
      method: 'post',
      url: `${env.getBasePath()}${url}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      data: parsedData
    };

    let response = await axios(config);
    console.log('post', data, response);
    return response.data;
  },

  async uploadPhoto(data, url) {
    let formData = new FormData();
    formData.append('token', encodeURI(env.getToken()));
    formData.append('tag', data.tag);
    formData.append('file', data.file);

    let config = {
      method: 'post',
      url: `${env.getBasePath()}${url}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    let response = await axios(config);
    return response.data;
  },

  async delete(DisplayTag) {
    return this.post({ tag: DisplayTag }, '/endpoints/trash/');
  },

  date(theDate) {
    return new Date(theDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  },

  serialize(obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  }
};

//makes undefined vars empty strings.
const checkUndefined = obj => {
  Object.keys(obj).forEach(function (key, index) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      checkUndefined(obj[key]);
    } else {
      obj[key] = obj[key] ? obj[key] : '';
    }
  });
  return obj;
};
