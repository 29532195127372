import { defineStore } from 'pinia';
export const useLoginStore = defineStore('login', {
  state: () => {
    return { userLoggedIn: { isLoggedIn: false } };
  },
  getters: {},
  actions: {
    updateUserLoggedIn(payload) {
      this.userLoggedIn = payload;
    }
  }
});
