<template>
  <div class="bg-brand-gray-600">
    <div class="container px-4">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="m-0 roboto-condensed-700" v-if="title">
            <i :class="['pi text-brand-teal-100 mr-2', icon]" v-if="icon"></i>
            {{ title }}
          </h1>
        </div>

        <div class="auto">
          <small class="roboto-400">
            <dateFormat :date="currentDate" :long="true" class="mr-2" />
            <timeFormat :time="currentDate" :interval="true" />
          </small>
        </div>

        <div class="col-auto">
          <router-link
            :to="{ name: 'editProfile' }"
            class="text-brand-gray-100">
            <Avatar
              :image="userLoggedIn.ProfileImage"
              shape="circle"
              size="large"
              v-tooltip.bottom="'Edit Profile'"
              v-if="userLoggedIn.ProfileImage" />
            <Avatar
              icon="pi pi-user"
              shape="circle"
              size="large"
              v-tooltip.bottom="'Edit Profile'"
              v-if="!userLoggedIn.ProfileImage" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/components/dateFormat.vue';
import timeFormat from '@/components/timeFormat.vue';

import { mapStores } from 'pinia';
import { useLoginStore } from '@/store/login';

export default {
  props: ['title', 'icon'],
  data() {
    return {
      currentDate: new Date()
    };
  },
  computed: {
    ...mapStores(useLoginStore),
    userLoggedIn() {
      return this.loginStore.userLoggedIn;
    }
  },
  components: {
    dateFormat,
    timeFormat
  },
  methods: {
    updateCurrentDate() {
      setTimeout(() => {
        this.currentDate = new Date();
        this.updateCurrentDate();
      }, 1000 * 60);
    }
  },
  mounted() {
    this.updateCurrentDate();
  }
};
</script>

<style lang="scss" scoped>
.row {
  height: 70px;
}

h1 {
  i {
    font-size: 1.5rem;
  }
}
</style>
