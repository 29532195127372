import api from './api';
import router from '@/router';
import { useLoginStore } from '@/store/login';

export default {
  async login(email, password) {
    let data = {
      Email: email,
      Password: password
    };

    window.localStorage.removeItem('agencyflow_login_token');

    let res = await api.post(data, '/endpoints/login/');
    console.log('form login', res);
    if (res?.token) {
      window.localStorage.setItem(
        'agencyflow_login_token',
        JSON.stringify(res.token)
      );
    }

    return res;
  },

  async logout() {
    window.localStorage.removeItem('agencyflow_login_token');
    router.go(0);
  },

  async autoLogin() {
    let data = {};
    let res = await api.post(data, '/endpoints/login/auto/');
    if (res.status !== 'success') {
      window.localStorage.removeItem('agencyflow_login_token');
    } else {
      res.user.isLoggedIn = true;
      const store = useLoginStore();
      store.updateUserLoggedIn(res.user);
    }
    return res;
  },

  getUserId() {
    const store = useLoginStore();
    return store.userLoggedIn?.DisplayTag;
  },

  getAdminStatus() {
    const store = useLoginStore();
    //console.log('admin status', store.userLoggedIn?.AdminStatus);
    return store.userLoggedIn?.AdminStatus;
  }
};
