<template>
  <div>
    <form
      class="p-4 shadow bg-brand-gray-600 rounded-lg"
      style="margin-bottom: 6rem"
      @submit.prevent="login()"
      name="loginForm"
      id="loginForm">
      <div class="row align-items-center mb-4">
        <div class="col-auto pr-0">
          <Avatar
            icon="pi pi-user"
            shape="circle"
            size="large"
            class="bg-brand-gray-600 text-brand-teal-300" />
        </div>

        <div class="col pl-0">
          <h1 class="m-0 roboto-300">Sign In</h1>
        </div>
      </div>

      <InputText
        placeholder="Email"
        v-model="email"
        class="mb-3"
        autofocus
        name="username" />
      <Password
        :toggleMask="true"
        :feedback="false"
        placeholder="Password"
        v-model="password"
        class="mb-3"
        name="password" />

      <Button type="submit" label="Sign In" icon="pi pi-sign-in" />

      <hr />
    </form>
  </div>
</template>

<script>
import loginService from '@/services/login';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      if (this.email && this.password) {
        let res = await loginService.login(this.email, this.password);
        if (res.status === 'error') {
          this.password = '';

          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Invalid email and/or password',
            life: 5000
          });
        } else {
          this.email = '';
          this.password = '';

          this.$toast.add({
            severity: 'info',
            summary: 'Success',
            detail: 'You have successfully signed in',
            life: 5000
          });
          this.$router.go();
        }
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Invalid email and/or password',
          life: 5000
        });
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
