import { createRouter, createWebHistory } from 'vue-router';
import loginService from '../services/login';

const routes = [
  {
    name: 'dashboard-old',
    path: '/dashboard-old',
    meta: {
      title: 'Agency Flow',
      icon: 'pi-home',
      permission: 0
    },
    component: () => import('../views/dashboard.vue')
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    meta: {
      title: 'Your Dashboard',
      icon: 'pi-home',
      permission: 0
    },
    component: () => import('../views/dashboard/dashboard.vue')
  },
  {
    name: 'timesheet',
    path: '/timesheet',
    meta: {
      title: 'Timesheet',
      icon: 'pi-clock',
      permission: 0
    },
    component: () => import('../views/timesheet.vue')
  },
  {
    name: 'contacts',
    path: '/contacts',
    meta: {
      title: 'Contacts',
      icon: 'pi-id-card',
      permission: 1
    },
    component: () => import('../views/contacts.vue')
  },
  {
    name: 'contacts.readOne',
    path: '/contacts/id/:id',
    meta: {
      title: 'Contacts',
      icon: 'pi-id-card',
      permission: 1
    },
    component: () => import('../views/single/contact/contact.vue')
  },
  {
    name: 'companies',
    path: '/contacts/companies',
    meta: {
      title: 'Companies',
      icon: 'pi-building',
      permission: 1
    },
    component: () => import('../views/companies.vue')
  },
  {
    name: 'projects',
    path: '/projects',
    meta: {
      title: 'Projects',
      icon: 'pi-briefcase',
      permission: 1
    },
    component: () => import('../views/projects.vue')
  },
  {
    name: 'projects.readOne',
    path: '/projects/id/:id/:tab?',
    meta: {
      title: 'Projects',
      icon: 'pi-briefcase',
      permission: 1
    },
    component: () => import('../views/single/project/project.vue')
  },
  {
    name: 'proposals',
    path: '/proposals',
    meta: {
      title: 'Proposals',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/proposals.vue')
  },
  {
    name: 'proposals.add',
    path: '/proposals/add/:projectId?', // optional project id to auto populate new proposal
    meta: {
      title: 'Add Proposal',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'proposals.edit',
    path: '/proposals/edit/:id',
    meta: {
      title: 'Edit Proposal',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'changeOrder.add',
    path: '/change-order/add/:projectId?', // optional project id to auto populate new proposal
    meta: {
      title: 'Add Change Order',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'changeOrder.edit',
    path: '/change-order/edit/:id',
    meta: {
      title: 'Edit Change Order',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'proposal.template.add',
    path: '/proposals/add/template/',
    meta: {
      title: 'Add Proposal Template',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'proposal.template.edit',
    path: '/proposals/edit/template/:id',
    meta: {
      title: 'Edit Proposal Template',
      icon: 'pi-send',
      permission: 1
    },
    component: () => import('../views/single/proposal/proposal.edit.vue')
  },
  {
    name: 'workOrders',
    path: '/work-orders',
    meta: {
      title: 'Work Orders',
      icon: 'pi-paperclip',
      permission: 1
    },
    component: () => import('../views/workOrders.vue')
  },
  {
    name: 'workOrders.readOne',
    path: '/work-orders/id/:id',
    meta: {
      title: 'Work Orders',
      icon: 'pi-paperclip',
      permission: 0
    },
    component: () => import('../views/single/workOrder.vue')
  },
  {
    name: 'schedule',
    path: '/schedule',
    meta: {
      title: 'Schedule',
      icon: 'pi-calendar',
      permission: 0
    },
    component: () => import('../views/schedule.vue')
  },
  {
    name: 'payrollReport',
    path: '/reports/payroll-report',
    meta: {
      title: 'Payroll Report',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/payrollReport.vue')
  },
  {
    name: 'personnelReport',
    path: '/reports/personnel-report',
    meta: {
      title: 'Personnel Report',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/personnelReport.vue')
  },
  {
    name: 'projectEstimates',
    path: '/reports/project-estimates',
    meta: {
      title: 'Project Estimates',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/projectEstimates.vue')
  },
  {
    name: 'projectStatus',
    path: '/reports/project-status',
    meta: {
      title: 'Project Status',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/projectStatus.vue')
  },
  {
    name: 'singleProject',
    path: '/reports/single-project',
    meta: {
      title: 'Single Project Report',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/singleProject.vue')
  },
  {
    name: 'taskReport',
    path: '/reports/task',
    meta: {
      title: 'Task Report',
      icon: 'pi-chart-line',
      permission: 2
    },
    component: () => import('../views/reports/taskReport.vue')
  },
  {
    name: 'manageUsers',
    path: '/settings/users/manage',
    meta: {
      title: 'Managed Users',
      icon: 'pi-users',
      permission: 2
    },
    component: () => import('../views/manageUsers.vue')
  },
  {
    name: 'taskTypes',
    path: '/setup/task-types',
    meta: {
      title: 'Task Types',
      icon: 'pi-sliders-h',
      permission: 1
    },
    component: () => import('../views/setup/taskTypes.vue')
  },
  {
    name: 'itemizedCosts',
    path: '/setup/itemized-costs',
    meta: {
      title: 'Itemized Costs',
      icon: 'pi-sliders-h',
      permission: 2
    },
    component: () => import('../views/setup/itemizedCosts.vue')
  },
  {
    name: 'proposalTemplates',
    path: '/setup/proposal-templates',
    meta: {
      title: 'Proposal Templates',
      icon: 'pi-sliders-h',
      permission: 2
    },
    component: () => import('../views/setup/proposalTemplates.vue')
  },
  {
    name: 'proposalTerms',
    path: '/setup/proposal-terms',
    meta: {
      title: 'Proposal Terms',
      icon: 'pi-sliders-h',
      permission: 1
    },
    component: () => import('../views/setup/proposalTerms.vue')
  },
  {
    name: 'projectTypes',
    path: '/setup/project-types',
    meta: {
      title: 'Project Types',
      icon: 'pi-sliders-h',
      permission: 2
    },
    component: () => import('../views/setup/projectTypes.vue')
  },
  {
    name: 'vendorItems',
    path: '/setup/vendor-items',
    meta: {
      title: 'Vendor Items',
      icon: 'pi-sliders-h',
      permission: 2
    },
    component: () => import('../views/setup/vendorItems.vue')
  },
  {
    name: 'editProfile',
    path: '/settings/profile/edit',
    meta: {
      title: 'Edit Profile',
      icon: 'pi-user-edit',
      permission: 0
    },
    component: () => import('../views/editProfile.vue')
  },
  {
    name: 'changelog',
    path: '/about/changelog',
    meta: {
      title: 'Change Log',
      icon: 'pi-history',
      permission: 0
    },
    component: () => import('../views/changelog.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: 'dashboard'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  //user isn't logged in, try to auto login with token from local storage.
  if (!loginService.getUserId()) {
    await loginService.autoLogin();
  }

  //get user permissions.
  let statusInt = 0;
  let status = loginService.getAdminStatus();
  if (status == 'Manager') {
    statusInt = 1;
  }
  if (status == 'Admin') {
    statusInt = 2;
  }

  console.log('routing', statusInt, to.meta.permission);
  if (statusInt >= to.meta.permission) {
    //continue to specified rout.
  } else {
    //no permission, go to dashboard.
    router.push({ name: 'dashboard' });
  }
});

export default router;
