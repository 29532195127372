export default {
  currency: num => {
    return num
      ? '$' +
          parseFloat(num)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      : '$0.00';
  },
  number: num => {
    return num
      ? parseFloat(num)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          .toString()
      : '0.00';
  }
};
